import Icon from "@ant-design/icons";
import { Button, Modal, notification, Spin } from "antd";
import { get, isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LogoBlanc from "../../../shared/assets/images/dynamique/logo-blanc.png";
import MecanicImage from "../../../shared/assets/images/dynamique/mecanicien.png";
import Heading from "../../../shared/components/stories/Heading";

import Form from "../../../shared/form";
import Input from "../../../shared/form/Field/Input";
import { AuthMutations } from "../../../shared/graphql/mutations";
import { UsersQueries } from "../../../shared/graphql/queries";
import { USER } from "../../../shared/graphql/queries/auth";
import { Store, StoreContext } from "../../../shared/store";
import { LOGIN, PATHS, ROLES } from "../../../utils/constants";
import { jwtDecode } from "../../../utils/helpers";

import { loginValidator } from "../validator";
import "./style.css";

const { PROFILE } = UsersQueries;
const { LOGIN_MUTATION } = AuthMutations;
const { Field, applyHandleSubmit, WrappedForm } = Form;

const Login = (props) => {
  const { onSubmit, form, getFieldValidator, submitting } = props;
  return (
    <div className="div-vertical growed">
      <div
        className="div-vertical center center-justified"
        style={{
          padding: "30px",
          width: "100%",
          background: "rgba(0,0,0,0.3)",
        }}
      >
        <img className="grand-logo" src={LogoBlanc} />
      </div>

      <div className="div-vertical growed center-justified">
        <WrappedForm onSubmit={onSubmit} form={form}>
          <div className="login__container--body--form ">
            <div style={{ margin: "20px 0px 30px 0px" }}>
              <h2>Connexion</h2>
              <label>
                Autoplus vous met à disposition une plateforme B2B, ayant pour
                objectif de rapprocher AUTOPLUS de ses clients et de façiliter
                leur vie quotidienne notamment qu'elle offre un catalogue
                complet de pièces, elle offre aussi la possiblité de passer des
                commandes en ligne et bien plus d'avantages...
              </label>
            </div>
            <Field name="username" getFieldValidator={getFieldValidator}>
              <Input
                placeholder="Nom d'utilisateur"
                size={"large"}
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            </Field>
            <Field name="password" getFieldValidator={getFieldValidator}>
              <Input
                placeholder="Mot de passe"
                type="password"
                size={"large"}
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            </Field>
            <div className="login__container--body--form--actions">
              <Button
                type="primary"
                size={"large"}
                htmlType="submit"
                disabled={submitting}
                title="Se connecter"
              >
                Se connecter
              </Button>
              <Button
                type="link"
                size={"large"}
                htmlType="submit"
                className="reset--btn"
                onClick={() => props.history.push("/reset-password/challenge")}
              >
                Réinitialiser mot de passe
              </Button>
            </div>
          </div>
        </WrappedForm>
      </div>
    </div>
  );
};

const Widget = (props) => {
  const { grantPrivateAccess, users_uid = [] } = useContext(StoreContext);
  const [modalVisibility, setModalVisibility] = useState({
    visible: false,
    message: "",
  });
  const [getUser, { loading }] = useLazyQuery(USER, {
    fetchPolicy: "network-only",
    onCompleted: ({ user }) => {
      const filterUser = users_uid.filter(({ id }) => user.id === id);

      if (
        get(user, "accesses", [])
          .map(({ device }) => device)
          .some(
            (device) =>
              !isEmpty(device) &&
              filterUser.some((userUid) => userUid.uid === device.guid)
          )
      ) {
        grantPrivateAccess();
        return history.push(PATHS.HOME);
      }
      return history.push(PATHS.ACCESS);
    },
  });

  const history = useHistory();
  const [getProfile, profileQuery] = useLazyQuery(PROFILE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      const { profile } = result;
      const {
        store: { setUser, user: storeUser, setToken },
      } = props;
      if (!profile) {
        setToken({ accessToken: "", refreshToken: "" });
        setUser({ user: {} });
        return setModalVisibility({
          visible: true,
          message: LOGIN.errorMessage,
        });
      }
      setUser({ user: { ...profile, ...storeUser } });
      if (get(profile, "role.name", "") === ROLES.ADMIN) {
        grantPrivateAccess();
        return history.push(PATHS.ADMIN.INDEX);
      }
      getUser({ variables: { where: {} } });
    },
  });

  const onComplete = async (result) => {
    const {
      data: {
        signin: { accessToken, refreshToken },
      },
    } = result;
    const {
      store: { setToken, setUser },
    } = props;
    const user = jwtDecode(accessToken);
    setToken({ accessToken, refreshToken });
    setUser({ user });
    if (!profileQuery.called) {
      getProfile({ variables: {} });
    }
  };

  const onError = () =>
    notification.open({
      message: "Error while logging in your password",
      description: "Please check that you have entered correct info",
    });
  const LoginForm = applyHandleSubmit(
    LOGIN_MUTATION,
    loginValidator,
    onComplete,
    onError
  )(Login);
  if (loading) return <Spin />;
  return (
    <>
      <div className="page-fullscreen">
        <div className="home-carousel">
          <Carousel touch={true} controls={false}>
            <Carousel.Item>
              <div className="div-carousel un"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="div-carousel deux"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="div-carousel trois"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="div-carousel quatre"></div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="voile"></div>
        <img className="mecanicien" src={MecanicImage} />

        <div className="container">
          <div className="voile-child col-lg-3" style={{ padding: "0px" }}>
            <div
              className="div-vertical center"
              style={{ height: "100%", width: "100%" }}
            >
              <LoginForm {...props} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={<Heading title={"error"} />}
        visible={modalVisibility.visible}
        footer={null}
        onCancel={() => setModalVisibility({ visible: false, message: "" })}
        width={900}
      >
        <div>
          <span>{modalVisibility.message}</span>
        </div>
      </Modal>
    </>
  );
};

export default Store(Widget);
