import { BarsOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Menu } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TabContext } from "../../../../Context/Tab";
import Assets from "../../../../shared/assets/images/Assets.svg";
import Quote from "../../../../shared/assets/images/Quote.svg";
import Invoice from "../../../../shared/assets/images/invoice.svg";
import Logout from "../../../../shared/assets/images/logout.svg";
import Package from "../../../../shared/assets/images/package.svg";
import Lock from "../../../../shared/assets/images/padlock.svg";
import Receipt from "../../../../shared/assets/images/receipt.svg";
import Reminder from "../../../../shared/assets/images/reminder.svg";
import User from "../../../../shared/assets/images/user.svg";
import { StoreContext } from "../../../../shared/store";
import { TABS_ACTIONS } from "../../../../utils/constants";

export default ({ clearStore }) => {
  const history = useHistory();
  const { dispatch } = useContext(TabContext);
  const { user } = useContext(StoreContext);

  return (
    <Dropdown
      placement="bottomRight"
      // placement="bottom"
      //  trigger={["click"]}
      overlay={
        <Menu className="select-box-list user_list">
          <Menu.Item onClick={() => history.push("/profile/me")}>
            <div className="user_list--item">
              <img src={User} alt={"user"} />
              <span>Mon compte</span>
            </div>
          </Menu.Item>

          {(user.role.name === "admin" || user.role.name === "commercial") && false && (
            <Menu.Item onClick={() => history.push("/mesclients")}>
              <div className="user_list--item">
                <img src={Package} alt={"package"} />
                <div className="div-horizontal" style={{ width: "100%" }}>
                  <span className="growed">Mes clients</span>
                  <Badge count={"NEW"} />
                </div>
              </div>
            </Menu.Item>
          )}

          <Menu.Item onClick={() => history.push("/recherches")}>
            <div className="user_list--item">
              <img src={Package} alt={"package"} />
              <div className="div-horizontal" style={{ width: "100%" }}>
                <span className="growed">Mes recherches</span>
                <Badge count={"NEW"} />
              </div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/achats")}>
            <div className="user_list--item">
              <img src={Package} alt={"package"} />
              <div className="div-horizontal" style={{ width: "100%" }}>
                <span className="growed">Mes achats</span>
                <Badge count={"NEW"} />
              </div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/entries")}>
            <div className="user_list--item">
              <img src={Package} alt={"package"} />
              <div className="div-horizontal" style={{ width: "100%" }}>
                <span className="growed">Mes entrées</span>
              </div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/retours")}>
            <div className="user_list--item">
              <img src={Package} alt={"package"} />
              <div className="div-horizontal" style={{ width: "100%" }}>
                <span className="growed">Mes retours</span>
                <Badge count={"NEW"} />
              </div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/orders")}>
            <div className="user_list--item">
              <img src={Package} alt={"package"} />
              <span>Mes commandes</span>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/invoices")}>
            <div className="user_list--item">
              <img src={Invoice} alt={"invoice"} />
              <span>Mes factures</span>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/assets")}>
            <div className="user_list--item">
              <img src={Assets} alt={"assets"} />
              <span>Mes avoirs</span>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/statements")}>
            <div className="user_list--item">
              <img src={Receipt} alt={"receipt"} />
              <span>Mes relevés</span>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => history.push("/quotes")}>
            <div className="user_list--item">
              <img src={Quote} alt={"quote"} />
              <span>Mes devis</span>
            </div>
          </Menu.Item>
          {/*   <Menu.Item onClick={() => history.push("/sav")}>
            <div className="user_list--item">
              <img src={Complaint} alt={"complaint"} />{" "}
              <span>Mes réclamations</span>
            </div>
          </Menu.Item> */}
          <Menu.Item onClick={() => history.push("/profile/supports")}>
            <div className="user_list--item">
              <img src={Reminder} alt={"Reminder"} />
              <span>Mes rappels</span>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/profile/reset-password")}>
            <div className="user_list--item">
              <img src={Lock} alt={"lock"} />{" "}
              <span>Mettre à jour mot de passe</span>
            </div>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              dispatch({ type: TABS_ACTIONS.CLEAR });
              clearStore();
            }}
          >
            <div className="user_list--item">
              <img src={Logout} alt={"logout"} /> <span>Se déconnecter</span>
            </div>
          </Menu.Item>
        </Menu>
      }
    >
      <BarsOutlined />
    </Dropdown>
  );
};
