import { Alert, Input, Modal, Spin, Tag } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Heading from "../stories/Heading";
const AllVideos = [
  {
    url: "demo_type_recherche.webm",
    preview: "demo_type_recherche.png",

    title: "Type de recherche",
    pointAborde: [
      "Recherche par Référence",
      "Recherche par Véhicule",
      "Recherche par VIN",
      "Recherche par Code moteur",
      "Recherche par équipementier",
      "Recherche par critère",
    ],
  },
  {
    url: "demo_filtre_recherche.webm",
    preview: "demo_filtre_recherche.png",

    title: "Interface résultats de recherche",
    pointAborde: [
      "Affichage graphique/compact",
      "Comparer des pièces",
      "Filtre par critère",
      "Filtre par nouveauté/top vente",
      "Filtre par famille",
      "Filtre par véhicule",
      "Ajouter un article au panier",
      "Ajouter plusieurs article au panier",
    ],
  },
  {
    url: "demo_panier.webm",
    preview: "demo_panier.png",

    title: "Ajouter au panier & passer une commande",
    pointAborde: [
      "Ajouter un article au panier",
      "Ajouter plusieurs article au panier",
      "Importer Excel",
      "Exporter Excel",
      "Passer la commande",
      "Suivi de la commande",
    ],
  },

  {
    url: "demo_achats.webm",
    preview: "demo_achats.png",

    title: "Interface suvi des achats",
    pointAborde: [
      "Chiffre affaire annuel/mensuel",
      "Top 10 référence",
      "Top 10 famille",
      "Top 10 marque",
    ],
  },

  {
    url: "demo_support.webm",
    preview: "demo_support.png",

    title: "Support technique",
    pointAborde: ["live chat", "whatsapp"],
  },
  {
    url: "demo_notifications.webm",
    preview: "demo_notifications.png",

    title: "Notifications",
    pointAborde: [
      "Activation de la notification",
      "Historique des notifications",
    ],
  },
];

const VideoPlayer = ({ video }) => {
  const [importedComponent, setImportedComponent] = useState(null);
  const [loading, setLoading] = useState(true);

  const { url, title, pointAborde, preview } = video;

  useEffect(() => {
    const importComponent = async () => {
      const module = await import("../../assets/videos/" + url);
      const video = module.default;

      const module2 = await import("../../assets/videos/previews/" + preview);
      const thumbnail = module2.default;

      setImportedComponent({ video, thumbnail });
      setLoading(false);
    };

    importComponent();
  }, []);

  return (
    <div
      className="div-vertical growed"
      style={{
        margin: "15px",
      }}
    >
      <div
        className="div-vertical"
        style={{ marginBottom: "8px", paddingLeft: "5px" }}
      >
        <label
          style={{
            fontWeight: "bold",
            margin: "0",

            flexGrow: 1,
            fontSize: "1.2rem",
          }}
        >
          {title}
        </label>
      </div>
      <div
        style={{
          height: "200px",
          width: "400px",
          overflow: "hidden",
          borderRadius: "5px",
          //    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "2px solid #eee",
          zoom: "calc(1 / 0.8)",
          background: "#000",
        }}
      >
        {loading ? (
          <div
            className="div-vertical center center-justified"
            style={{ width: "100%", height: "100%" }}
          >
            <Spin />
          </div>
        ) : (
          <ReactPlayer
            url={get(importedComponent, "video", null)}
            width="100%"
            height="100%"
            muted={true}
            playing={false}
            config={{
              file: { attributes: { controlsList: "nodownload" } },
            }}
            controls={true}
            loop={false}
            light={get(importedComponent, "thumbnail", null)}
          />
        )}
      </div>
      <div
        className="div-horizontal wrapped"
        style={{
          marginTop: "15px",
          width: "400px",
        }}
      >
        {pointAborde.map((point, index) => (
          <Tag key={index} style={{ margin: "5px" }} color="#f50">
            {point}
          </Tag>
        ))}
      </div>
    </div>
  );
};

const ModalVideoDemo = (props) => {
  const { visible, setVisible } = props;
  const [motCle, setMotCle] = useState("");

  return (
    <Modal
      className="form-modal full-screen-modal"
      centered
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      title={<Heading title={"Vidéos Démonstration"} />}
    >
      <div
        style={{
          margin: "15px",
        }}
      >
        <Alert
          style={{ marginBottom: "20px" }}
          closable
          message={"Important"}
          description={
            <label style={{ fontWeight: "500" }}>
              Chers utilisateurs,
              <br />
              <br /> Nous tenons à vous rappeler l'importance cruciale de la
              confidentialité des données. À cet égard, nous vous prions de ne
              pas télécharger les vidéos démo disponibles sur notre plateforme.
              Ces vidéos sont fournies à des fins d'illustration et ne doivent
              en aucun cas être partagées, téléchargées ou diffusées en dehors
              de notre environnement sécurisé. Le non-respect de cette directive
              pourrait compromettre la confidentialité des informations
              sensibles. Nous vous encourageons vivement à respecter ces
              consignes afin de protéger la vie privée et la sécurité des
              parties impliquées. Merci de votre compréhension et de votre
              coopération.
              <br />
              <br />
              Cordialement,
              <br />
              Équipe AUTOPLUS
            </label>
          }
          type="warning"
          showIcon
        />
        <Input
          size="large"
          value={motCle}
          onChange={(e) => {
            setMotCle(e.target.value);
          }}
          placeholder="Tapez une fonctionnalité, un titre..."
        />
      </div>
      <div className="div-vertical ">
        <div className="div-horizontal wrapped">
          {AllVideos.filter(
            (video) =>
              video.title.toLowerCase().includes(motCle.toLowerCase()) ||
              video.pointAborde.filter((point) =>
                point.toLowerCase().includes(motCle.toLowerCase())
              ).length > 0
          ).map((video) => (
            <VideoPlayer key={video.title} video={video} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalVideoDemo;
