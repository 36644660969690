import { useMutation } from "@apollo/react-hooks";
import { notification } from "antd";
import cuid from "cuid";
import { isEmpty } from "lodash";
import find from "lodash/find";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  CartItemMutations,
  ItemMutations,
  WishListItemsMutations,
} from "../../../../../../../shared/graphql/mutations";
import { CartItemsQueries } from "../../../../../../../shared/graphql/queries";

import { WISH_LIST_ITEMS } from "../../../../../../../shared/graphql/queries/wish-list-items";
import {
  DEFAULT_PAGE_SIZE_CART,
  ITEM_WAREHOUSE_DESCRIPTION,
} from "../../../../../../../utils/constants";
import ZoomImage from "../../../Detail/ZoomImage";
import Compact from "./Modes/Compact";
import Normal from "./Modes/Normal";

const { CREATE_CART_ITEM } = CartItemMutations;
const { CREATE_ITEM } = ItemMutations;
const { CART_ITEMS } = CartItemsQueries;
const { CREATE_WISH_LIST_ITEM } = WishListItemsMutations;

const MODES = {
  Compact,
  Normal,
};

export const Card = ({
  mode,
  article: {
    referenceArticle,
    code,
    name,
    brand: { name: brandName, id: brandId },
    item: {
      id: itemId,
      oem,
      prices = [],
      discount,
      itemWareHouses = [],
      catalogCode,
      code: itemCode,
      photo,
      photoVersion,
      photo2,
      photoVersion2,
      nouveau,
      topVente,
      dateArrivage,
      application1,
      application2,
      application3,
    },
    oens,
    assemblyGroup: assemblyGroups,
    thumbnails = [],
    substitutes,
    attributes,
  },
  MDDS,
  history,
  account,
  article,
  setVisible,
  setSelectedArticle,
}) => {
  //// console.log("dateArrivage 0", article);
  const [articleAvecPrix, setArticleAvecPrix] = useState(article);

  const [showList, setShowList] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [visibleImage, setVisibleImage] = useState(false);
  const [addCartItem, { loading: cartItemLoading }] = useMutation(
    CREATE_CART_ITEM,
    {
      update: (cache, { data: { createCartItem } }) => {
        const {
          cartItems,
          cartItems: { data, count },
        } = cache.readQuery({
          query: CART_ITEMS,
          variables: {
            where: { deleted: false },
            take: DEFAULT_PAGE_SIZE_CART,
          },
        });
        cache.writeQuery({
          query: CART_ITEMS,
          variables: {
            where: { deleted: false },
            take: DEFAULT_PAGE_SIZE_CART,
          },
          data: {
            cartItems: {
              ...cartItems,
              data: [createCartItem, ...data],
              count: count + 1,
            },
          },
        });
      },
      onCompleted: () => {},
    }
  );
  const [addItem, { loading: itemLoading }] = useMutation(CREATE_ITEM, {
    onCompleted: ({ createItem: { id } }) =>
      addCartItem({
        variables: {
          data: {
            item: {
              id,
              thumbnail: get(thumbnails, "[0].url", ""),
            },
            quantity,
            id: cuid(),
          },
        },
      }),
  });
  const handleCartPress = () => {
    console.log("handleCartPress:", name);
    itemId === -1
      ? addItem({
          variables: {
            data: {
              id: code,
              code,
              name: name,
              thumbnail: get(thumbnails, "[0].url", ""),
            },
          },
        })
      : addCartItem({
          variables: {
            data: {
              item: {
                id: itemId,
                thumbnail: get(thumbnails, "[0].url", ""),
              },
              quantity,
              id: cuid(),
            },
          },
        });
  };
  const detailLink = (article) => {
    // console.log("detailLink", article);

    setVisible(true);
    setSelectedArticle(article);
  };
  const detailImage = () => {
    setVisibleImage(true);
  };

  const [createWishListItem, { data, loading, error }] = useMutation(
    CREATE_WISH_LIST_ITEM,
    {
      update: (cache, { data: { createWishListItem } }) => {
        const {
          wishListItems,
          wishListItems: { data, count },
        } = cache.readQuery({
          query: WISH_LIST_ITEMS,
          variables: { where: { deleted: false } },
        });

        cache.writeQuery({
          query: WISH_LIST_ITEMS,
          variables: { where: { deleted: false } },
          data: {
            wishListItems: {
              ...wishListItems,
              data: [...data, createWishListItem],
              count: count + 1,
            },
          },
        });
      },
      onCompleted: () => {
        notification.success({
          message: "Ajouté avec succès aux list des souhaits",
        });
      },
    }
  );

  const handleWishListIconPress = (description) => {
    itemId === -1
      ? createWishListItem({
          variables: {
            data: {
              description: description,
              name: name,
              item: {
                id: code,
              },
              image: get(thumbnails, "[0].url", ""),
              quantity,
            },
          },
        })
      : createWishListItem({
          variables: {
            data: {
              description: description,
              name: name,
              item: {
                id: itemId,
              },
              image: get(thumbnails, "[0].url", ""),
              quantity,
            },
          },
        });
  };

  const addToWishList = (itemWareHouses, prices) => {
    const wareHouses = itemWareHouses.filter((item) => item.quantity > 0);

    const status = (prices, wareHouses) => {
      if (prices.length && isEmpty(wareHouses))
        return ITEM_WAREHOUSE_DESCRIPTION.NOT_AVAILAIBLE;
      if (prices.length && !isEmpty(wareHouses))
        return ITEM_WAREHOUSE_DESCRIPTION.FAVORITE;

      return ITEM_WAREHOUSE_DESCRIPTION.NOT_HANDLED;
    };

    handleWishListIconPress(status(prices, wareHouses));
  };

  const checkIfMdds = () =>
    find(get(MDDS, "mdds", []), (item) => {
      return item.name.toLowerCase() === brandName.toLowerCase();
    });

  const Componnent = MODES[mode];

  return (
    <>
      <Componnent
        showList={showList}
        setShowList={setShowList}
        referenceArticle={referenceArticle}
        code={code}
        name={name}
        assemblyGroup={
          article.assemblyGroups.length > 0
            ? article.assemblyGroups[0].name
            : article.name
        }
        brandName={brandName}
        itemId={itemId}
        itemCode={itemCode}
        prices={prices}
        discount={discount}
        itemWareHouses={itemWareHouses}
        oens={oens}
        thumbnails={thumbnails}
        photo={photo}
        photoVersion={photoVersion}
        photo2={photo2}
        photoVersion2={photoVersion2}
        nouveau={nouveau}
        topVente={topVente}
        dateArrivage={dateArrivage}
        application1={application1}
        application2={application2}
        application3={application3}
        substitutes={substitutes}
        attributes={attributes}
        history={history}
        account={account}
        detailLink={detailLink}
        detailImage={detailImage}
        quantity={quantity}
        setQuantity={setQuantity}
        handleCartPress={handleCartPress}
        handleWishListIconPress={handleWishListIconPress}
        cartItemLoading={cartItemLoading}
        itemLoading={itemLoading}
        checkIfMdds={checkIfMdds}
        addToWishList={addToWishList}
        article={article}
        setArticleAvecPrix={setArticleAvecPrix}
        articleAvecPrix={articleAvecPrix}
      ></Componnent>

      <ZoomImage
        thumbnails={thumbnails}
        photo={photo}
        photoVersion={photoVersion}
        photo2={photo2}
        photoVersion2={photoVersion2}
        itemId={itemId}
        visible={visibleImage}
        setVisible={setVisibleImage}
        oem={oem}
        checkIfMdds={checkIfMdds}
      ></ZoomImage>
    </>
  );
};

Card.propTypes = {
  internal: PropTypes.bool,
  hasEquivalence: PropTypes.bool,
};

Card.defaultProps = {
  internal: false,
  hasEquivalence: true,
};

export default Card;
