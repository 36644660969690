const schedule = {
  SummerMatin: "Lundi - vendredi: 09H00-13H00 15H00-19H00",
  SummerSoire: "Samedi: 09H00 - 13H00",
  WinterMatin: "Lundi - vendredi: 08H30 12H30 14H30 - 18H30",
  WinterSoire: "Samedi: 08H30 - 12H30",
}

const scheduleLayoun = {
  SummerWinter: "Lundi - samedi: 09H30 - 19H00",
}

export default {
  "SIÉGE-CASABLANCA": {
    position: [33.597832, -7.4900416],
    address: "Bd AHL LOGHLAM - Sidi Bernoussi Zenata 20400",
    phone: "0522328117 / 0522516161",
    fax: "0522328046",
    email: "contact@maghrebaccessoires.com",
    schedule
  },
  MEKNES: {
    position: [33.8999626, -5.5414653],
    address:
      "27, rue Abdelmoumen Al Mouahidi  - Résidence  Khozama - Mèknes El Menzeh",
    phone: " 0535403823 / 0535403824",
    fax: "",
    email: "meknes@maghrebaccessoires.com",
    schedule
  },
  AGADIR: {
    position: [30.42018, -9.59815],
    address:
      "Avenue Cadi Ayyad N° 6 (rdc) Résidence Al Majd Lotissement Amsernat, 80000 Agadir",
    phone: "0528231296",
    fax: "",
    email: "agadir@maghrebaccessoires.com",
    schedule
  },

  "AIT MELLOUL": {
    position: [30.3503644, -9.4963473],
    address: "Bloc 1 N°4 Bis Lot Ait Said Ait Melloul 80150",
    phone: "0528247552",
    fax: "",
    email: "aitmelloul@maghrebaccessoires.com",
    schedule
  },
  LAAYOUNE: {
    position: [27.1306814, -13.187389],
    address: "Avenue AL HIZAM a coté de Dépôt AL ATLAS",
    phone: "0528981289",
    fax: "",
    email: "laayoune@maghrebaccessoires.com",
    schedule: scheduleLayoun
  },
  OUJDA: {
    position: [34.684636, -1.9425833],
    address: "8 Bis Rue El Rhazaouat - oujda",
    phone: "0536703574",
    fax: "",
    email: "oujda@maghrebaccessoires.com",
    schedule
  },
  GAB: {
    position: [33.5896033, -7.6062931],
    address: "BD khouribga, 7 rue maarakat ait abdellah, Casablanca 20250",
    phone: "0522909704",
    fax: "",
    email: "oulfa@maghrebaccessoires.com",
    schedule
  },
  RABAT: {
    position: [34.0200757, -6.8496432],
    address: "Quartier Ocean Rue Leningrad N° 19 Rabat",
    phone: "0537736095 / 0537736888",
    fax: "",
    email: "rabat@maghrebaccessoires.com",
    schedule
  },
  TANGER: {
    position: [35.769008, -5.817444],
    address: "30, Bvd d’Anfa - Hay Souan - 90000 Tanger",
    phone: "0539321043",
    fax: "",
    email: "tanger@maghrebaccessoires.com",
    schedule
  },
  MARRAKECH: {
    position: [31.6346248, -8.0158078],
    address: "11, Bvd Mansour Eddahbi , 40000 Marrakech",
    phone: "0524458762",
    fax: "",
    email: "marrakech@maghrebaccessoires.com",
    schedule
  },
  BERNOUSSI: {
    position: [33.597857399999995, -7.4887204],
    address: "Bd AHL LOGHLAM - Sidi bernoussi Zenata 20400",
    phone: "0522759254",
    fax: "0522756130",
    email: "bernoussi@maghrebaccessoires.com",
    schedule
  },

  BERRECHID: {
    position: [33.246856689453125, -7.590753078460693],
    address: "RUE EL MOURABITINE Z.I de BERRECHID 26100",
    phone: "0522759254",
    fax: "0522756130",
    email: "berrchid@maghrebaccessoires.com",
    schedule
  },
  LAMBERT: {
    position: [33.597857399999995, -7.4887204],
    address: "Bd AHL LOGHLAM - Sidi bernoussi Zenata 20400",
    phone: "0522759254",
    fax: "0522756130",
    email: "lambert@maghrebaccessoires.com",
    schedule
  },

  FES: {
    position: [34.0334727, -4.9985553],
    address:
      "Rés Mariane 1 , Rue Derrar ibn Azour Angle Avenue des FAR ( ville nouvelle ) FES",
    phone: "0535943831",
    fax: "",
    email: "fes@maghrebaccessoires.com",
    schedule
  }
};
