import gql from "graphql-tag";

export const ACCOUNT = gql`
  query Account($where: AccountWhereInput) {
    account(where: $where) {
      id
      title
      description
      city
      address
      firstName
      lastName
      birthday
      company
      idFiscal
      patent
      rc
      ice
      cnss
      agency
      referent
      phone1
      phone2
      email
    }
  }
`;
export const ACCOUNTS = gql`
  query Accounts(
    $where: AccountWhereInput
    $skip: Int
    $like: AccountWhereInput
    $take: Int
  ) {
    accounts(where: $where, like: $like, take: $take, skip: $skip) {
      data {
        id
        title
        description
        city
        address
        firstName
        lastName
        birthday
        company
        idFiscal
        patent
        rc
        ice
        cnss
        agency
        referent
        commercial {
          id
          nom
        }
        phone1
        phone2
        email
      }
      count
    }
  }
`;

export default {
  ACCOUNTS,
  ACCOUNT,
};
