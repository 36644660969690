import { Button, Col, Input, Radio, Row, Select, Spin } from "antd";
import flattenDeep from "lodash/flattenDeep";
import get from "lodash/get";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Query } from "react-apollo";
import { TabContext } from "../../../../../Context/Tab";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { StoreContext } from "../../../../../shared/store";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const { Option } = Select;
const { MODELS } = ManufacturersQueries;

const filterModels = (elements, filter) => {
  const { year } = filter;
  const result = isNil(year)
    ? elements
    : elements.filter((model) => {
        return (
          moment(model.yearOfConstructionFrom, "YYYYMM").format("YYYY") <=
            filter.year &&
          filter.year <=
            moment(model.yearOfConstructionTo, "YYYYMM").format("YYYY")
        );
      });
  return sortBy(result, (element) => element.name);
};

const Models = ({
  years,
  models,
  manufacturer,
  filter,
  setFilter,
  filterByName,
  setFilterByName,
  search,
}) => {
  const { dispatch } = useContext(TabContext);
  const { compact, setCompact } = useContext(StoreContext);

  const manufacturerBean = search.manufacturerBean;

  //

  const formatTecdocDateToText = (dateStr) => {
    console.log("dateStr", dateStr);
    if (!dateStr) return "Présent";
    // Extraire l'année et le mois de la chaîne
    const year = dateStr.toString().slice(0, 4);
    const month = dateStr.toString().slice(4, 6);

    // Convertir le mois numérique en nom de mois
    //const monthName = MONTH_NAMES[parseInt(month, 10) - 1];

    // Retourner le format "MM yyyy"
    return `${month}/${year}`;
  };

  const Normal = () => {
    return (
      <Row
        className="div-horizontal"
        style={{
          justifyContent: "stretch",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
        gutter={16}
      >
        {models.map((model) => (
          <Col
            key={model.id}
            span={4}
            style={{ marginTop: "5px", marginBottom: "5px" }}
            onClick={() => {
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.VEHICLES,
                  props: {
                    search: {
                      manufacturer: manufacturer.id,
                      manufacturerBean: manufacturerBean,

                      model: model.id,
                      modelBean: model,
                    },
                  },
                },
              });
            }}
          >
            <div
              className={`div-vertical center center-justified tab__detail--article--brand`}
            >
              <object
                style={{
                  marginBottom: 5,

                  height: 40,
                }}
                key={model.id}
                data={`https://media.carparts-cat.com/webdata2/kmodthumb/${model.id}.png`}
                type="image/png"
              >
                <img
                  style={{ height: 40 }}
                  src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                  alt="Modèle"
                />
              </object>

              <label>
                {model.name}
                <br />
                {"(" +
                  formatTecdocDateToText(model.yearOfConstructionFrom) +
                  " - " +
                  formatTecdocDateToText(model.yearOfConstructionTo) +
                  ")"}
              </label>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const Compact = () => {
    return (
      <div className="grid">
        {models.map((model) => (
          <div
            key={model.id}
            className="compact_card_item"
            onClick={() => {
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.VEHICLES,
                  props: {
                    search: {
                      manufacturer: manufacturer.id,
                      manufacturerBean: manufacturerBean,

                      model: model.id,
                      modelBean: model,
                    },
                  },
                },
              });
            }}
          >
            <label>
              {model.name}
              <br />
              {"(" +
                formatTecdocDateToText(model.yearOfConstructionFrom) +
                " - " +
                formatTecdocDateToText(model.yearOfConstructionTo) +
                ")"}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const MODES = {
    normal: Normal,
    compact: Compact,
  };

  const Componnent = MODES[compact ? "compact" : "normal"];

  // console.log("models", models);

  return (
    <div className="home_section_bg">
      <div className="section full-height">
        <div className="div-vertical growed">
          <div className="container">
            <div
              className="titre-div-horizontal"
              style={{ padding: "10px 0px" }}
            >
              <Breadcrumb location={{ search }} />

              <Button
                type="primary"
                size={"large"}
                onClick={() => setFilter({ year: null })}
              >
                Réinitialiser
              </Button>

              <Select
                showSearch
                size={"large"}
                placeholder="Année"
                style={{
                  width: "160px",
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                {years.map((year) => (
                  <Option
                    onClick={() => setFilter({ year })}
                    value={`wareHouse-${year}`}
                    key={year}
                    to="/"
                  >
                    {year}
                  </Option>
                ))}
              </Select>
              <div className="div-horizontal">
                <div
                  className="years growed"
                  style={{ display: "none", marginRight: 15 }}
                >
                  {years.map((year) => (
                    <Button
                      key={year}
                      shape="round"
                      size={"large"}
                      className="btn_filter_year"
                      onClick={() => setFilter({ year })}
                    >
                      {year}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="div-horizontal center" style={{ marginTop: 10 }}>
              <Input
                style={{ width: "100%", marginRight: 15 }}
                size={"large"}
                placeholder="Série de modèle"
                value={filterByName}
                onChange={(_) => setFilterByName(_.target.value)}
              />

              <Radio.Group
                type="primary"
                defaultValue={compact ? "compact" : "normal"}
                buttonStyle="solid"
                size="large"
                onChange={(value) =>
                  setCompact(value.target.value === "compact")
                }
              >
                <Radio.Button value="normal">Graphique</Radio.Button>
                <Radio.Button value="compact">Compact</Radio.Button>
              </Radio.Group>
            </div>

            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <Componnent></Componnent>;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const [filter, setFilter] = useState({ year: null });

  const [filterByName, setFilterByName] = useState();
  const { search, history } = props;
  const id = get(search, "manufacturer", {});

  //
  //

  const manufacturer = { id: +id, name: null };

  const manufacturerBean = search.manufacturerBean;
  const variables = {
    where: { manufacturer: { id } },
  };

  return (
    <Query query={MODELS} variables={variables}>
      {({ loading, error, data }) => {
        if (loading) return <Spin></Spin>;
        if (error) return `Error! ${error.message}`;
        const elements = data.models.data;

        const filtredByName = elements.filter(({ name }) =>
          filterByName
            ? name.toLowerCase().includes(filterByName.toLowerCase())
            : true
        );

        const years = uniq(
          flattenDeep(
            elements.map(({ yearOfConstructionFrom, yearOfConstructionTo }) => [
              yearOfConstructionFrom,
              yearOfConstructionTo,
            ])
          )
            .filter((x) => x)
            .map((x) => moment(x, "YYYYMM").format("YYYY"))
        )
          .sort()
          .reverse();

        const specificManufacturers = [72, 16, 88, 1820, 95, 99];
        const sortType = specificManufacturers.includes(manufacturer.id)
          ? "specific"
          : "default";
        const sortConfig = {
          specific: (a, b) => a.name.localeCompare(b.name),
          default: (a, b) => b.name.localeCompare(a.name),
        };
        const models = filterModels(filtredByName, filter);

        return (
          <>
            <Models
              years={years}
              models={models}
              manufacturer={manufacturer}
              setFilter={setFilter}
              filter={filter}
              setFilterByName={setFilterByName}
              filterByName={filterByName}
              history={history}
              search={search}
            />
          </>
        );
      }}
    </Query>
  );
};
